import React from "react"
import ProductOverviewPage from "./ProductOverviewPage"

const ProductOverviewPageTemplate = ({ data, pageContext }) => {

  const content = JSON.parse(pageContext.content)

  const subNavItems = pageContext.subNavData.map((subNavItem) => ({
    link: subNavItem.real_path,
    link_text: subNavItem.name,
  }))

  const breadcrumbs = pageContext.breadcrumbs
    .map((item) => ({ link: item.real_path, link_text: item.name }))
    ?.reverse()

  return (
    <ProductOverviewPage
      content={content}
      breadcrumbs={breadcrumbs}
      ProductCategoriesCollection={pageContext.product_categories}
    />
  )
}

export default ProductOverviewPageTemplate
