import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import Hero from "../../src/components/modules/Hero"
import ProductCards from "../../src/components/modules/ProductCards"

const ProductOverviewPage = ({
  content,
  breadcrumbs,
  ProductCategoriesCollection,
}) => {
  const initialProductCategories = ProductCategoriesCollection

  const categorySorting = [
    "Klassiker",
    "Exoten",
    "Saftkompositionen",
    "Bio",
    "Sanft wie Seide",
    "Saisonfrisch",
  ]

  return (
    <Layout>
      <SEO content={content} og_image={content?.image?.filename} />
      <Hero
        block={{
          headline: content.headline,
          subheadline: content.subheadline,
          image: content.image,
        }}
      />
      {categorySorting.map((cat, index) => (
        <ProductCards
          block={{
            headline: cat,
            headline_level: "h4",
            card_items: initialProductCategories.find(
              (category) => category.category === cat
            )?.products,
          }}
        />
      ))}
      {/* {initialProductCategories &&
        initialProductCategories.map((cat, index) => (
          <ProductCards
            block={{
              headline: cat.category,
              headline_level: "h4",
              card_items: cat.products,
            }}
          />
        ))} */}

      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default ProductOverviewPage
